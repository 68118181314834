:root {
  --background-primary: #090a09;
  --primary-green: #009a57;
  --gray-light: rgba(250, 250, 250, 0.2);
  --off-white: #bfbfbf;
}

body {
  overflow-x: hidden;
}

@media (min-width: 1340px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1024px) and (max-width: 1340px) {
  html {
    font-size: 14px; /* Adjust font size for medium screens */
  }
}
@media (min-width: 728px) and (max-width: 1034px) {
  html {
    font-size: 12px; /* Adjust font size for medium screens */
  }
}

@media (max-width: 728px) {
  html {
    font-size: 8px; /* Adjust font size for small screens */
  }
}

@font-face {
  font-family: 'RocGrotesk';
  src: url('./fonts/rocgrotesk-bold.woff') format('woff');
}
@font-face {
  font-family: 'RocGroteskCond';
  src: url('./fonts/rocgroteskcond-bold.woff') format('woff');
}
@font-face {
  font-family: 'InterSemiBold';
  src: url('./fonts//Inter_18pt-SemiBold.ttf') format('woff');
}

.primary {
  /* background-color: #009a57; */
  /* display: none; */
  animation: primary 20s linear infinite;
}

.secondary {
  /* background-color: red; */
  animation: secondary 20s linear infinite;
}

@keyframes primary {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-81%);
  }
}

@keyframes secondary {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-81%);
  }
}

.primary-mobile {
  /* background-color: #009a57; */
  /* display: none; */
  animation: primary-mobile 20s linear infinite;
}

.secondary-mobile {
  /* background-color: red; */
  animation: secondary-mobile 20s linear infinite;
}

@keyframes primary-mobile {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-75%);
  }
}

@keyframes secondary-mobile {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-75%);
  }
}
.dynamic-text {
  font-size: 1.1em;
  color: var(--primary-green);
}

.dynamic-text:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;
  background-color: var(--background-primary);
  border-left: 2px solid var(--primary-green);
}

.dynamic-text.animate::before {
  animation: animate 3s steps(7);
}

@keyframes animate {
  40%,
  60% {
    left: calc(100% + 2px);
  }
  100% {
    left: 0%;
  }
}
